import {
    mdiCellphoneInformation,
    mdiPhoneIncoming,
    mdiPhoneMissed,
    mdiPhoneOutgoing,
    mdiPhoneLog,
    mdiAccountPlus,
    mdiNotePlus,
    mdiNotebookPlus,
    mdiRefresh,
    mdiLogout,
    mdiAccountBox,
    mdiDatabaseArrowRight,
    mdiDotsHorizontalCircle,
} from '@mdi/js';

export const SvgIconPath = {
    cellPhoneInfo: mdiCellphoneInformation,
    phoneIncoming: mdiPhoneIncoming,
    phoneMissed: mdiPhoneMissed,
    phoneOutgoing: mdiPhoneOutgoing,
    phoneLog: mdiPhoneLog,
    accountPlus: mdiAccountPlus,
    notePlus: mdiNotePlus,
    notebookPlus: mdiNotebookPlus,
    refresh: mdiRefresh,
    logout: mdiLogout,
    account: mdiAccountBox,
    database: mdiDatabaseArrowRight,
    menu: mdiDotsHorizontalCircle,
};
