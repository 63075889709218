import { get, HttpResponse, post } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { LeadSearchData } from '@/interfaces/leads/LeadSearchData';
import { LeadUpdateData } from '@/interfaces/leads/LeadUpdateData';
import { LeadCreateData } from '@/interfaces/leads/LeadCreateData';
import { LeadCreateResponse } from '@/interfaces/leads/LeadCreateResponse';
import { NoticeCreateData } from '@/interfaces/notices/NoticeCreateData';

export async function fetchLeads(query: string): Promise<HttpResponse<LeadSearchData[]>> {
    return await get<LeadSearchData[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/leads/?query=${query}`,
        TokenType.ACCESS,
    );
}

export async function addLead(data: LeadCreateData): Promise<HttpResponse<LeadCreateResponse>> {
    return await post<LeadCreateResponse>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/leads/`,
        data,
        TokenType.ACCESS,
    );
}

export async function updateLead(id: number, data: LeadUpdateData): Promise<HttpResponse<null>> {
    return await post<null>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/leads/${id}/`,
        data,
        TokenType.ACCESS,
    );
}

export async function addNotice(
    lead_id: number,
    data: NoticeCreateData,
): Promise<HttpResponse<null>> {
    return await post<null>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/leads/${lead_id}/`,
        data,
        TokenType.ACCESS,
    );
}
