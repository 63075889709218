
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import auth from '@/store/auth/auth';
    import { BModalComponent } from 'buefy/types/components';
    import { TicketCreateData } from '@/interfaces/tickets/TicketCreateData';
    import { addTicket } from '@/api/Tickets';

    @Component
    export default class TicketAdd extends Vue {
        @Prop() customerId!: number;

        private formData: TicketCreateData = {
            title: '',
            request_text: '',
            partner_id: this.customerId,
        };

        private isValidName = false;

        @Watch('formData.name')
        onNameChange() {
            this.isValidName = Boolean(this.formData.title.length);
        }

        async dispatchCreateTicket() {
            let response = await addTicket(this.formData);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();
                response = await addTicket(this.formData);
            }

            if (
                !response ||
                !(await response.parsedBody) ||
                !response.parsedBody ||
                response.status !== 201
            ) {
                throw new Error('Error while creating ticket.');
            }

            return response.parsedBody;
        }

        async createTicket() {
            // dispatch lead creation
            await this.dispatchCreateTicket();

            // close modal window
            this.close();

            this.$buefy.toast.open('Ticket erstellt.');
        }

        close() {
            (this.$parent as BModalComponent).close();
        }
    }
