
    import { Component, Vue } from 'vue-property-decorator';
    import auth from '@/store/auth/auth';
    import { buildCarERPUrl, getFixtureLevelName } from '@/utils/misc';
    import { ErpCar } from '@/interfaces/cars/ErpCar';
    import { fetchErpCars } from '@/api/ErpCars';
    import { FixtureLevel } from '@/enums/FixtureLevel';

    @Component
    export default class CarList extends Vue {
        data: ErpCar[] = [];

        get isPaginated() {
            return this.data.length > 30;
        }

        getErpUrl(id: number): string {
            return buildCarERPUrl(id);
        }

        getFixtureLevelName(f: FixtureLevel): string {
            return getFixtureLevelName(f);
        }

        async fetchErpCars() {
            let response = await fetchErpCars();

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await fetchErpCars();
            }

            const cars = await response.parsedBody;

            if (cars) {
                return cars;
            } else {
                throw new Error('Error while getting contracts.');
            }
        }

        async mounted() {
            this.data = await this.fetchErpCars();
        }
    }
