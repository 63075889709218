
    import { Component, Vue } from 'vue-property-decorator';
    import { EventBus } from '@/main';
    import { GlobalEvent } from '@/enums/GlobalEvent';
    import { isAdmin } from '@/utils/jwt';
    import auth from '@/store/auth/auth';

    @Component
    export default class SidebarNav extends Vue {
        private open = false;

        get isAdmin() {
            return isAdmin(auth.loginData?.access_token);
        }

        logout() {
            EventBus.$emit(GlobalEvent.LOGOUT);
        }

        mounted() {
            EventBus.$on(GlobalEvent.OPEN_SIDEBAR, () => {
                this.open = true;
            });
        }
    }
