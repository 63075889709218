
    import { Component, Vue } from 'vue-property-decorator';
    import auth from '@/store/auth/auth';
    import { fetchContracts } from '@/api/Contracts';
    import { Contract } from '@/interfaces/contracts/Contract';
    import { Status } from '@/enums/contracts/Status';
    import { mdiCheckboxMarkedCircle, mdiMinusBoxOutline, mdiProgressCheck } from '@mdi/js';
    import SvgIcon from '@/components/SvgIcon.vue';
    import { buildContractERPUrl } from '@/utils/misc';

    @Component({
        components: {
            SvgIcon,
        },
    })
    export default class ContractList extends Vue {
        data: Contract[] = [];

        get isPaginated() {
            return this.data.length > 30;
        }

        getErpUrl(id: number): string {
            return buildContractERPUrl(id);
        }

        getStatusIcon(status: Status) {
            if (status === Status.ACTIVE) {
                return mdiCheckboxMarkedCircle;
            }

            if (status === Status.PENDING) {
                return mdiProgressCheck;
            }

            if (status === Status.INACTIVE) {
                return mdiMinusBoxOutline;
            }
        }

        async fetchContracts() {
            let response = await fetchContracts();

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await fetchContracts();
            }

            const contracts = await response.parsedBody;

            if (contracts) {
                return contracts;
            } else {
                throw new Error('Error while getting contracts.');
            }
        }

        async mounted() {
            this.data = await this.fetchContracts();
        }
    }
