
    import { Component, Vue } from 'vue-property-decorator';
    import { EventBus } from '@/main';
    import { GlobalEvent } from '@/enums/GlobalEvent';
    import auth from '@/store/auth/auth';
    import voice from '@/store/voice/voice';
    import SidebarNav from '@/components/SidebarNav.vue';
    import SvgIcon from '@/components/SvgIcon.vue';
    import { SvgIconPath } from '@/utils/SvgIconPath';
    import AppHeader from '@/components/AppHeader.vue';
    import AppFooter from '@/components/AppFooter.vue';
    import emitter from '@/utils/mitt';

    @Component({
        components: {
            AppFooter,
            AppHeader,
            SvgIcon,
            SidebarNav,
        },
    })
    export default class App extends Vue {
        private progress = 20;
        private loadingIsVisible = false;
        private svgicon = SvgIconPath;
        showLoader = false;

        get auth() {
            return auth;
        }

        openSidebar() {
            EventBus.$emit(GlobalEvent.OPEN_SIDEBAR);
        }

        mounted() {
            emitter.on('showLoader', () => (this.showLoader = true));
            emitter.on('hideLoader', () => (this.showLoader = false));

            EventBus.$on(GlobalEvent.AUTH_FAILED, () => {
                // clearing state
                voice.commitCurrentSipAccount({ currentSipAccount: null });
                voice.commitCurrentPhoneNumber({ currentPhoneNumber: null });
                voice.commitCurrentCalls({ currentCalls: [] });
                auth.commitLoginData({ loginData: null });

                // go to login component
                this.$router.push({ name: 'login' });
            });

            EventBus.$on(GlobalEvent.START_LOADING, () => {
                this.progress = 20;
                this.loadingIsVisible = true;
            });

            EventBus.$on(GlobalEvent.STOP_LOADING, () => {
                this.progress = 100;

                setTimeout(() => {
                    this.loadingIsVisible = false;
                }, 800);
            });

            EventBus.$on(GlobalEvent.LOGOUT, () => {
                this.processLogout();
            });
        }

        processLogout() {
            EventBus.$emit(GlobalEvent.START_LOADING);
            auth.dispatchLogout();
            EventBus.$emit(GlobalEvent.STOP_LOADING);

            // redirecting to Log in view.
            this.$router.push({ name: 'login' });
        }
    }
