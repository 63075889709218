import { CallDetail } from '@/interfaces/voice/CallDetail';
import { ActualCall } from '@/interfaces/voice/ActualCall';
import { CallType } from '@/enums/voice/CallType';
import { ActualCallType } from '@/enums/voice/ActualCallType';
import { HangupCause } from '@/enums/voice/HangupCause';
import voice from '@/store/voice/voice';
import { CallerType } from '@/enums/voice/CallerType';

export function processOutbound(actualCall: ActualCall) {
    const rawCallOutbound = actualCall.calls.find((call) => call.call_type === CallType.OUTBOUND);

    if (rawCallOutbound && rawCallOutbound.destination_number) {
        actualCall.externalNumber = rawCallOutbound.destination_number;
        actualCall.start = rawCallOutbound.start_stamp;
    }

    if (rawCallOutbound && rawCallOutbound.callerid_number) {
        const sipacc = voice.state.availableSipAccounts.find(
            (acc) => acc.username === rawCallOutbound.callerid_number,
        );

        if (sipacc) {
            actualCall.sipUsername = sipacc.username;
        }
    }
}

export function processInbound(actualCall: ActualCall) {
    const rawCallInbound = actualCall.calls.find((call) => call.call_type === CallType.INBOUND);

    if (rawCallInbound && rawCallInbound.callerid_number && rawCallInbound.start_stamp) {
        actualCall.externalNumber = rawCallInbound.callerid_number;
        actualCall.start = rawCallInbound.start_stamp;

        const rawCallSipForward = actualCall.calls.find(
            (call) => call.call_type === CallType.SIPFORWARD_USER,
        );

        if (rawCallSipForward && rawCallSipForward.destination_number) {
            const sipacc = voice.state.availableSipAccounts.find(
                (acc) => acc.username === rawCallSipForward.destination_number,
            );

            if (sipacc) {
                actualCall.sipUsername = sipacc.username;
            }
        }
    }
}

/**
 * Build the actual calls from raw calls.
 * Means: Aggregate the single records to one 'call', e.g. forwards and in/outbounds.
 *
 * @param rawCalls
 */
export function buildActualCalls(rawCalls: CallDetail[]): ActualCall[] {
    if (!rawCalls) {
        return [];
    }

    const cgs: ActualCall[] = [];
    let currentRawCallAppended = false;

    for (const current of rawCalls) {
        let type;
        let missed = false;

        if (
            current.call_type === CallType.INBOUND ||
            current.call_type === CallType.SIPFORWARD_USER
        ) {
            type = ActualCallType.INBOUND;
            missed = current.bleg_uuid === null || current.hangup_cause === HangupCause.NO_ANSWER;
        } else {
            missed =
                current.hangup_cause === HangupCause.NO_USER_RESPONSE ||
                current.hangup_cause === HangupCause.ORIGINATOR_CANCEL ||
                current.hangup_cause === HangupCause.NO_ANSWER;
            type = ActualCallType.OUTBOUND;
        }

        currentRawCallAppended = false;

        // loop through items and look for an actual call to append this call detail
        for (const cg of cgs) {
            for (const rc of cg.calls) {
                if (
                    rc.bleg_uuid === current.uuid ||
                    current.bleg_uuid === rc.uuid ||
                    rc.main_leg_uuid === current.uuid ||
                    current.main_leg_uuid === rc.uuid
                ) {
                    cg.calls.push(current);

                    // move callerInfo to ActualCall object
                    if (current.callerInfo) {
                        cg.callerInfo = current.callerInfo;
                        delete current.callerInfo;
                    }

                    currentRawCallAppended = true;
                }
            }
        }

        if (!currentRawCallAppended) {
            let ci;

            // move callerInfo to ActualCall object
            if (current.callerInfo) {
                ci = current.callerInfo;
                delete current.callerInfo;
            }

            const cg: ActualCall = {
                type: type,
                missed: missed,
                calls: [current],
            };

            if (ci) {
                cg.callerInfo = ci;
            }

            cgs.push(cg);
        }
    }

    // return the actual calls, but ignore internal forwards
    // these are 'actual calls' with a single SIPFORWARD_USER item and..
    // 'actual calls' with a single INBOUND item with callerid_name 'Outbound Call' (yes, that's weird...)
    // @TODO: maybe ignore them on server side?
    return cgs.filter(
        (ac) =>
            !(ac.calls.length === 1 && ac.calls[0].call_type === CallType.SIPFORWARD_USER) &&
            !(
                ac.calls.length === 1 &&
                ac.calls[0].call_type === CallType.INBOUND &&
                ac.calls[0].callerid_name === 'Outbound Call'
            ),
    );
}

/**
 * Group similar missed calls.
 *
 * @param actualCalls
 */
export function groupCalls(actualCalls: ActualCall[]) {
    // using reduce() to group calls
    const reducer = (accumulator: ActualCall[], item: ActualCall) => {
        if (!item.missed) {
            // no missed item, just push and forget!
            accumulator.push(item);
        } else {
            // searching for missed items to group
            const i = accumulator.findIndex(
                (ac) =>
                    ac.missed && ac.externalNumber === item.externalNumber && ac.type === item.type,
            );

            if (i !== -1) {
                // found, so increment countMissed
                const obj: ActualCall = accumulator[i];

                if (obj && obj.countMissed) {
                    obj.countMissed += 1;
                }
            } else {
                // not found, so push it and set countMissed to 1
                item.countMissed = 1;
                accumulator.push(item);
            }
        }

        return accumulator;
    };

    // return reduced list
    return actualCalls.reduce(reducer, []);
}

/**
 * Filter to only show the 'open' missed calls.
 * These are calls where no future recall is found.
 *
 * @param actualCalls
 */
export function filterOpenMissed(actualCalls: ActualCall[]) {
    // use reduce() to get rid of missed calls which have a successful recall
    // means: reduce the array to only contain elements without recall or succesful new call
    const reducer = (accumulator: ActualCall[], item: ActualCall) => {
        if (!item.start || !accumulator.length) {
            // start date is undefined or accumulator is empty, so add it to result
            // former should actually never happen
            accumulator.push(item);
        } else {
            // parse start date for comparison
            const item_start = Date.parse(item.start);

            // looking for recalls
            const i = accumulator.findIndex((ac) => {
                if (!ac.start) {
                    // no start date set -> suppose this is no recall
                    // this should actually never happen
                    return false;
                }

                // parse date
                const start = Date.parse(ac.start);

                // if phone number matches, call is after current item's call, item type is INBOUND,
                // item is missed and current item is not missed (got a callback)
                return (
                    ac.externalNumber === item.externalNumber &&
                    item_start <= start &&
                    item.type === ActualCallType.INBOUND &&
                    item.missed &&
                    !ac.missed
                );
            });

            if (i === -1) {
                // no recall found, add it
                // otherwise do nothing (of course)
                accumulator.push(item);
            }
        }

        return accumulator;
    };

    return actualCalls
        .reduce(reducer, [])
        .filter((ac) => ac.type === ActualCallType.INBOUND && ac.missed);
}

/**
 * Build the callertype string from CallerType.
 *
 * @param ct
 */
export function buildCallerType(ct: CallerType) {
    switch (ct) {
        case CallerType.CUSTOMER:
            return 'Kunde';
        case CallerType.LEAD:
            return 'Lead';
        case CallerType.OTHER:
            return 'Andere';
        default:
            return '';
    }
}
