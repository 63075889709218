/**
 * Parse the JWT String
 *
 * @param jwt
 */
function parseJwt(jwt: string | undefined) {
    if (!jwt) {
        return false;
    }

    if (jwt.split('.').length < 3) {
        throw new Error('Corrupt JWT');
    }

    return JSON.parse(atob(jwt.split('.')[1]));
}

/**
 * Check if the given JWT data is valid.
 *
 * @param jwt
 */
export function isValidJwt(jwt: string | undefined) {
    const data = parseJwt(jwt);

    if (!data) {
        return false;
    }

    const exp = new Date(data.exp * 1000); // JS deals with dates in milliseconds since epoch
    const now = new Date();

    return now < exp;
}

/**
 * Extract the abbr from JWT.
 *
 * @param jwt
 */
export function getUserAbbr(jwt: string | undefined) {
    const data = parseJwt(jwt);

    if (data && 'abbr' in data) {
        return data.abbr;
    }

    return null;
}

/**
 * Extract the user ID from JWT.
 *
 * @param jwt
 */
export function getUserID(jwt: string | undefined) {
    const data = parseJwt(jwt);

    if (data && 'sub' in data) {
        return data.sub;
    }

    return null;
}

/**
 * Extract the username from JWT.
 *
 * @param jwt
 */
export function getUsername(jwt: string | undefined) {
    const data = parseJwt(jwt);

    if (data && 'username' in data) {
        return data.username;
    }

    return null;
}

/**
 * Get the user's groups from JWT.
 *
 * @param jwt
 */
export function getUserGroups(jwt: string | undefined) {
    const data = parseJwt(jwt);

    if (data && 'groups' in data) {
        return data.groups;
    }

    return null;
}

/**
 * Check if current User is an administrator.
 *
 * @param jwt
 */
export function isAdmin(jwt: string | undefined) {
    const groups = getUserGroups(jwt);

    return groups && groups.auth && Array.isArray(groups.auth) && groups.auth.includes('admin');
}
