
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class SvgIcon extends Vue {
        @Prop() path!: string;
        @Prop({ default: '0 0 24 24' }) viewBox!: string;
        @Prop({ default: '' }) svgClasses!: string;
        @Prop({ default: '' }) title!: string;
        @Prop({ default: true }) svgShow!: boolean;
        @Prop({ default: false }) pointer!: boolean;

        private svgClassProperty = '';
        private styleProperty = '';

        mounted() {
            if (!this.svgClasses) {
                this.svgClassProperty = 'icon';
            } else {
                this.svgClassProperty = `icon ${this.svgClasses}`;
            }

            if (this.pointer) {
                this.styleProperty = 'cursor: pointer';
            }
        }
    }
