import { render, staticRenderFns } from "./Voice.vue?vue&type=template&id=346ebff6&scoped=true"
import script from "./Voice.vue?vue&type=script&lang=ts"
export * from "./Voice.vue?vue&type=script&lang=ts"
import style0 from "./Voice.vue?vue&type=style&index=0&id=346ebff6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_t3gmm35n6tkn624j6aeupb7v5y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "346ebff6",
  null
  
)

export default component.exports