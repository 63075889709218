
    import { Component, Vue } from 'vue-property-decorator';
    import CallDetailRecord from '@/components/voice/CallDetailRecord.vue';
    import voice from '@/store/voice/voice';
    import Header from '@/components/voice/Header.vue';
    // @ts-ignore
    import { io } from 'socket.io-client';
    import { getUserGroups } from '@/utils/jwt';
    import auth from '@/store/auth/auth';
    import { CallNotification } from '@/interfaces/voice/CallNotification';
    import { IncomingCallEvent } from '@/enums/voice/IncomingCallEvent';
    import { capitalizeFirstLetter, isLeadCreateResponse } from '@/utils/misc';
    import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
    import { buildActualCalls, groupCalls, processInbound, processOutbound } from '@/utils/voice';
    import { ActualCallType } from '@/enums/voice/ActualCallType';
    import SvgIcon from '@/components/SvgIcon.vue';
    import NotificationSection from '@/components/voice/NotificationSection.vue';
    import { LeadCreateResponse } from '@/interfaces/leads/LeadCreateResponse';
    import { CallerType } from '@/enums/voice/CallerType';
    import { LeadSearchData } from '@/interfaces/leads/LeadSearchData';
    import { CallFilter } from '@/enums/voice/CallFilter';

    @Component({
        components: {
            NotificationSection,
            Header,
            CallDetailRecord,
            SvgIcon,
        },
    })
    export default class Voice extends Vue {
        private currentIncomingCallData: CallNotification | null = null;

        get displayedCalls() {
            let displayed;

            switch (voice.state.callFilter) {
                case CallFilter.INBOUND_ANSWERED:
                    displayed = this.actualCalls.filter(
                        (cg) => cg.type === ActualCallType.INBOUND && !cg.missed,
                    );
                    break;
                case CallFilter.INBOUND_MISSED:
                    displayed = this.actualCalls.filter(
                        (cg) => cg.type === ActualCallType.INBOUND && cg.missed,
                    );
                    break;
                case CallFilter.OUTBOUND_ANSWERED:
                    displayed = this.actualCalls.filter(
                        (cg) => cg.type === ActualCallType.OUTBOUND && !cg.missed,
                    );
                    break;
                case CallFilter.OUTBOUND_MISSED:
                    displayed = this.actualCalls.filter(
                        (cg) => cg.type === ActualCallType.OUTBOUND && cg.missed,
                    );
                    break;
                default:
                    displayed = this.actualCalls;
            }

            if (voice.state.groupSimilar) {
                // group the calls (respect missed calls option)
                displayed = groupCalls(displayed);
            }

            return displayed;
        }

        get actualCalls() {
            if (!this.rawCalls) {
                return [];
            }

            const acs = buildActualCalls(this.rawCalls);

            for (const ac of acs) {
                if (ac.type === ActualCallType.INBOUND) {
                    processInbound(ac);
                } else if (ac.type === ActualCallType.OUTBOUND) {
                    processOutbound(ac);
                }
            }

            return acs;
        }

        get rawCalls() {
            return voice.state.currentCalls;
        }

        onSaveLeadFromNotification(data: LeadCreateResponse | LeadSearchData) {
            // Event emitted by NotificationSection, is either LeadCreationResponse or LeadSearchData
            if (!data || !this.currentIncomingCallData) {
                throw new Error('Cannot save lead, data or current incoming call is undefined.');
            }

            if (isLeadCreateResponse(data)) {
                if (data.createData) {
                    this.currentIncomingCallData.callerInfo = {
                        id: data.id,
                        type: CallerType.LEAD,
                        name: data.createData.name,
                        email: data.createData.email_from,
                    };
                }
            } else {
                this.currentIncomingCallData.callerInfo = {
                    id: data.id,
                    type: CallerType.LEAD,
                    name: data.name,
                    email: data.email_from,
                };
            }
        }

        closeNotification() {
            this.currentIncomingCallData = null;
        }

        /**
         * Doing fancy frontend stuff when incoming call is received.
         *  - show snackbar
         *  - show HTML5 desktop notification
         *
         * @param data: CallNotification
         */
        onIncomingCall(data: CallNotification) {
            // use libphonenumber for formatting the callerid_number
            const callerNumber: PhoneNumber = parsePhoneNumber(data.variables.callerid_number);

            // check for groups
            const voicegroups = getUserGroups(auth.loginData?.access_token).voice;

            if (!voicegroups || !Array.isArray(voicegroups) || !voicegroups.length) {
                throw new Error('User has no voice group');
            }

            // build the group name
            const group = capitalizeFirstLetter(voicegroups[0]);

            let notification_body = '';

            if (data.callerInfo) {
                notification_body = `\nName: ${data.callerInfo?.name}\nE-Mail: ${data.callerInfo?.email}`;
            } else {
                notification_body = `Nummer: ${callerNumber.formatInternational()}`;
            }

            this.currentIncomingCallData = data;

            // show desktop browser notification
            Notification.requestPermission().then(function (result) {
                if (result === 'granted') {
                    new Notification(`Eingehender Anruf für ${group}`, {
                        body: notification_body,
                        icon: require('../assets/img/sip-icon.png'),
                    });
                }
            });
        }

        async mounted() {
            if (auth.isAuthenticated) {
                // retrieve sip accounts
                await voice.dispatchRetrieveSipAccounts();

                // current namespace is based currently logged in user's group
                const groups = getUserGroups(auth.loginData?.access_token);
                const namespace = groups.voice[0];

                if (namespace) {
                    // create socketio object with url
                    const socket = io(`${process.env.VUE_APP_CRM_SERVICE_HOST}/${namespace}`, {
                        transports: ['websocket'], // force using websockets only (no longpolling)
                    });

                    // start incoming call event listener
                    socket.on(
                        IncomingCallEvent.INCOMING_CALL_FORWARD_INITIATION,
                        (data: CallNotification) => {
                            this.onIncomingCall(data);
                        },
                    );
                }
            }
        }
    }
