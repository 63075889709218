
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { CallNotification } from '@/interfaces/voice/CallNotification';
    import SvgIcon from '@/components/SvgIcon.vue';
    import { SvgIconPath } from '@/utils/SvgIconPath';
    import { getUserGroups } from '@/utils/jwt';
    import auth from '@/store/auth/auth';
    import { buildLeadLink, buildPartnerLink, capitalizeFirstLetter } from '@/utils/misc';
    import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
    import LeadCreation from '@/components/LeadCreation.vue';
    import NoticeAdd from '@/components/NoticeAdd.vue';
    import { LeadCreateResponse } from '@/interfaces/leads/LeadCreateResponse';
    import LeadSearch from '@/components/LeadSearch.vue';
    import { LeadSearchData } from '@/interfaces/leads/LeadSearchData';
    import { CallerType } from '@/enums/voice/CallerType';
    import { buildCallerType } from '@/utils/voice';

    @Component({
        components: {
            LeadSearch,
            SvgIcon,
            LeadCreation,
            NoticeAdd,
        },
    })
    export default class NotificationSection extends Vue {
        @Prop() currentIncomingCallData!: CallNotification;

        private svgicon = SvgIconPath;
        private showLeadCreation = false;
        private showNoticeAdding = false;
        private ct: unknown;

        onSaveLead(data: LeadCreateResponse | LeadSearchData) {
            // emit saveLead event to Voice Component
            // data is either a response from lead creation or from data from LeadSearch

            this.$emit('saveLead', data);

            // don't know why this is actually necessary. It shouldn't.
            // When creating lead, the component is updated, when updating lead via LeadSearch: not...
            this.$forceUpdate();
        }

        get namespace() {
            return capitalizeFirstLetter(getUserGroups(auth.loginData?.access_token).voice[0]);
        }

        get callerNumber(): PhoneNumber {
            return parsePhoneNumber(this.currentIncomingCallData.variables.callerid_number);
        }

        get callerType() {
            if (this.currentIncomingCallData.callerInfo) {
                return buildCallerType(this.currentIncomingCallData.callerInfo.type);
            }

            return '';
        }

        /**
         * Get the erp link according to caller type.
         *
         * @param erp_id
         */
        getERPLink(erp_id: number): string {
            if (this.currentIncomingCallData.callerInfo?.type === CallerType.LEAD) {
                return buildLeadLink(erp_id);
            } else if (this.currentIncomingCallData.callerInfo?.type === CallerType.CUSTOMER) {
                return buildPartnerLink(erp_id);
            }

            return '';
        }

        beforeCreate() {
            this.ct = CallerType;
        }
    }
