import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { getStoreBuilder } from 'vuex-typex';
import { VoiceState } from '@/store/voice/voice';
import { AuthState } from '@/store/auth/auth';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

/**
 * Establish the vuex persistent storage.
 * @see https://github.com/championswimmer/vuex-persist
 */
const vuexPersist = new VuexPersistence<RootState>({
    storage: window.localStorage, // use localStorage as storage
    reducer: (state) => ({
        auth: {
            loginData: state.auth.loginData, // only persist loginData (access_token, refresh_token and message bullshit)
            apiKeys: state.auth.apiKeys,
        },
    }),
});

export interface RootState {
    auth: AuthState;
    voice: VoiceState;
}

const store: Store<RootState> = getStoreBuilder<RootState>().vuexStore({
    plugins: [vuexPersist.plugin],
});

export default store; // <-- "store" to provide to root Vue
