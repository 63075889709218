
    import { Component, Vue } from 'vue-property-decorator';
    import auth from '@/store/auth/auth';
    import { LoginRequest } from '@/interfaces/LoginRequest';
    import { EventBus } from '@/main';
    import { GlobalEvent } from '@/enums/GlobalEvent';

    @Component
    export default class Login extends Vue {
        private username = '';
        private password = '';
        private title = document.title;

        async doLogin() {
            EventBus.$emit(GlobalEvent.START_LOADING);

            const loginReq: LoginRequest = {
                username: this.username,
                password: this.password,
            };

            // first: commit the mutation to make username/password useable for login dispatch
            auth.commitLoginReq({ loginReq });

            // dispatch login
            await auth.dispatchLogin();

            if (auth.isAuthenticated) {
                // login successful, retrieve api keys, redirect to application.
                await auth.dispatchAPIKeys();
                await this.$router.push({ name: 'voice' });
            }

            EventBus.$emit(GlobalEvent.STOP_LOADING);
        }

        mounted() {
            // register the 'LOGIN_FAILED' event
            EventBus.$on(GlobalEvent.LOGIN_FAILED, () => {
                this.$buefy.dialog.alert({
                    title: 'Fehler',
                    message: 'Login fehlgeschlagen, Benutzername/Passwort falsch.',
                    type: 'is-danger',
                    ariaRole: 'alertdialog',
                    ariaModal: true,
                });
            });
        }
    }
