import { get, HttpResponse, post } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { ErpCar } from '@/interfaces/cars/ErpCar';
import { ImportFile } from '@/interfaces/cars/ImportFile';
import { DataParseResult } from '@/interfaces/cars/DataParseResult';
import { CarImportData } from '@/interfaces/cars/CarImportData';
import { DataImportRowResult } from '@/interfaces/cars/DataImportRowResult';

export async function fetchErpCars(): Promise<HttpResponse<ErpCar[]>> {
    return await get<ErpCar[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/cars/`,
        TokenType.ACCESS,
    );
}

export async function fetchAvailableErpCars(): Promise<HttpResponse<ErpCar[]>> {
    return await get<ErpCar[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/cars/`,
        TokenType.ACCESS,
    );
}

export async function readCarImportFile(data: ImportFile): Promise<HttpResponse<DataParseResult>> {
    return await post<DataParseResult>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/carimporter/read/`,
        data,
        TokenType.ACCESS,
    );
}

export async function importCarDataRow(
    data: CarImportData,
): Promise<HttpResponse<DataImportRowResult>> {
    return await post<DataImportRowResult>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/carimporter/import/row/`,
        data,
        TokenType.ACCESS,
    );
}
