import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/views/Login.vue';
import auth from '@/store/auth/auth';
import Voice from '@/views/Voice.vue';
import Admin from '@/views/Admin.vue';
import { EventBus } from '@/main';
import { GlobalEvent } from '@/enums/GlobalEvent';
import Contracts from '@/views/Contracts.vue';
import Cars from '@/views/Cars.vue';
import THG from '@/views/THG.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { title: 'Login' },
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        meta: { title: 'Administration' },
        children: [
            {
                path: 'user',
                component: () => import('@/components/admin/users/User.vue'),
                children: [
                    {
                        path: '',
                        name: 'userlist',
                        component: () => import('@/components/admin/users/UserList.vue'),
                        meta: { title: 'Administration | Userliste' },
                    },
                    {
                        path: 'edit/:id',
                        name: 'useredit',
                        component: () => import('@/components/admin/users/UserEdit.vue'),
                        meta: { title: 'Administration | User editieren' },
                    },
                    {
                        path: 'add',
                        name: 'useradd',
                        component: () => import('@/components/admin/users/UserAdd.vue'),
                        meta: { title: 'Administration | User hinzufügen' },
                    },
                ],
            },
        ],
    },
    {
        path: '/voice',
        name: 'voice',
        component: Voice,
        meta: { title: 'Voice' },
    },
    {
        path: '/contracts',
        component: Contracts,
        meta: { title: 'EASING Verträge' },
        children: [
            {
                path: '',
                name: 'contractlist',
                component: () => import('@/components/contracts/ContractList.vue'),
                meta: { title: 'EASING Kundenverträge' },
            },
            {
                path: 'add/:obj_number?',
                name: 'contractadd',
                component: () => import('@/components/contracts/ContractAdd.vue'),
                meta: { title: 'EASING-Vertrag hinzufügen' },
            },
        ],
    },
    {
        path: '/cars',
        component: Cars,
        meta: { title: 'ERP Autos' },
        children: [
            {
                path: '',
                name: 'carlist',
                component: () => import('@/components/cars/CarList.vue'),
                meta: { title: 'ERP Autos' },
            },
            {
                path: '/import',
                name: 'carimporter',
                component: () => import('@/components/cars/CarImport.vue'),
                meta: { title: 'ERP Auto Importer ' },
            },
        ],
    },
    {
        path: '/thg',
        component: THG,
        meta: { title: 'THG' },
        children: [
            {
                path: '',
                redirect: { name: 'thgcarlist' },
            },
            {
                path: 'cars',
                name: 'thgcarlist',
                component: () => import('@/components/thg/cars/THGCarList.vue'),
                meta: { title: 'THG | Fahrzeuge' },
            },
            {
                path: 'cars/new',
                name: 'newthgcar',
                component: () => import('@/components/thg/cars/NewTHGCar.vue'),
            },
            {
                path: 'users',
                name: 'thguserlist',
                component: () => import('@/components/thg/user/THGUserList.vue'),
                meta: { title: 'THG | User' },
            },
            {
                path: 'cars/users/:userId',
                name: 'thgusercarslist',
                component: () => import('@/components/thg/cars/THGUserCarsList.vue'),
                meta: { title: 'THG | User | Fahrzeuge' },
            },
            {
                path: 'cars/:carId',
                name: 'thgcar',
                component: () => import('@/components/thg/cars/THGCar.vue'),
                meta: { title: 'THG | Fahrzeuge | editieren' },
            },
            {
                path: 'users/:userId',
                name: 'thguser',
                component: () => import('@/components/thg/user/THGUser.vue'),
                meta: { title: 'THG | User editieren' },
            },
            {
                path: 'co2-equivalents',
                name: 'thgco2equivalentslist',
                component: () =>
                    import('@/components/thg/co2_equivalents/THGCO2EquivalentsList.vue'),
                meta: { title: 'THG | CO₂-Äquivalente' },
            },
            {
                path: 'co2-equivalents/new',
                name: 'newthgco2equivalent',
                component: () => import('@/components/thg/co2_equivalents/NewTHGCO2Equivalent.vue'),
            },
            {
                path: 'co2-equivalents/:co2EquivalentId',
                name: 'thgco2equivalent',
                component: () => import('@/components/thg/co2_equivalents/THGCO2Equivalent.vue'),
                meta: { title: 'THG | CO₂-Äquivalent editieren' },
            },
            {
                path: 'tags',
                name: 'thgtagslist',
                component: () => import('@/components/thg/tags/THGTagsList.vue'),
                meta: { title: 'THG | Tags' },
            },
            {
                path: 'tags/new',
                name: 'newthgtag',
                component: () => import('@/components/thg/tags/NewTHGTag.vue'),
            },
            {
                path: 'tags/:tagId',
                name: 'thgtag',
                component: () => import('@/components/thg/tags/THGTag.vue'),
                meta: { title: 'THG | Tag editieren' },
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// use client-side isAuthenticated method to check for authentication on each view but Login
// otherwise go to Log in View.
router.beforeEach((to, from, next) => {
    if (to.name !== 'login' && next.name !== 'login' && !auth.isAuthenticated) {
        next({ name: 'login' });
    } else {
        // set the site title dynamic by route's meta definition
        let title = process.env.VUE_APP_TITLE as string;

        // append from route's meta definition if it's available
        if (to.meta && to.meta.title) {
            title += ` | ${to.meta.title}`;
        }

        document.title = title;

        // start the progress bar
        EventBus.$emit(GlobalEvent.START_LOADING);

        // always call next() in beforeEach navigation guard
        next();
    }
});

router.afterEach(() => {
    // finalize the progress bar
    EventBus.$emit(GlobalEvent.STOP_LOADING);
});

export default router;
