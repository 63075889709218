
    import Header from '@/components/cars/Header.vue';
    import { Component, Vue } from 'vue-property-decorator';
    import CarList from '@/components/cars/CarList.vue';

    @Component({
        components: {
            CarList,
            Header,
        },
    })
    export default class Cars extends Vue {}
