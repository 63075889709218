import './router/componentHooks';
import Vue from 'vue';
import Buefy from 'buefy';

import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';

import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

// import main stylesheet for all components
import '@/assets/style/main.scss';

// @ts-ignore
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import resetStore from '@/storenew/reset-store';
// @ts-ignore
import LottieAnimation from 'lottie-web-vue';
import * as process from 'process';

// use Vue plugin without options
Vue.use(VueSvgInlinePlugin);

Vue.use(LottieAnimation);

Vue.use(Buefy, {
    defaultProgrammaticPromise: true,
});

Vue.use(PiniaVuePlugin);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(resetStore);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue: Vue,
        dsn: 'https://e29418aa71bf4c7687e2b453ed2529f0@o301292.ingest.sentry.io/5588542',
        environment: process.env.VUE_APP_ENV,
    });
}

// export a global EventBus for emitting global events (e.g. for authorization stuff).
export const EventBus = new Vue();

new Vue({
    router,
    store,
    pinia,
    render: (h) => h(App),
}).$mount('#app');
