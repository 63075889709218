import { CallDetail } from '@/interfaces/voice/CallDetail';
import { get, HttpResponse, post, put } from '@/api/Api';
import { CDRRequest } from '@/interfaces/voice/CDRRequest';
import { SIPAccount } from '@/interfaces/SIPAccount';
import { TokenType } from '@/enums/TokenType';
import { CallerInfoRequest } from '@/interfaces/voice/CallerInfoRequest';

export async function fetchSipAccounts(): Promise<HttpResponse<SIPAccount[]>> {
    return await get<SIPAccount[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/voice/sip-accounts/`,
        TokenType.ACCESS,
    );
}

export async function fetchCalls(reqData: CDRRequest): Promise<HttpResponse<CallDetail[]>> {
    return await post<CallDetail[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/voice/cdrs/`,
        reqData,
        TokenType.ACCESS,
    );
}

export async function addCallerinfo(cr: CallerInfoRequest): Promise<HttpResponse<null>> {
    return await put<null>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/voice/cdrs/`,
        cr,
        TokenType.ACCESS,
    );
}
