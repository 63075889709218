
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { LeadSearchData } from '@/interfaces/leads/LeadSearchData';
    import { fetchLeads, updateLead } from '@/api/Leads';
    import auth from '@/store/auth/auth';
    import { LeadUpdateData } from '@/interfaces/leads/LeadUpdateData';

    @Component
    export default class LeadSearch extends Vue {
        @Prop() externalNumber!: string;

        selected: LeadSearchData | null = null;
        isFetching = false;
        data: LeadSearchData[] = [];

        async dispatchSaveLead() {
            if (!this.selected || !this.selected.id) {
                throw new Error('Cannot update lead, selected ID is not defined.');
            }

            const leaddata: LeadUpdateData = {
                phone: this.externalNumber,
            };

            let response = await updateLead(this.selected.id, leaddata);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();
                response = await updateLead(this.selected.id, leaddata);
            }

            if (!response || !(await response.parsedBody) || response.status !== 200) {
                throw new Error('Error while updating user.');
            }

            this.$emit('saveLead', this.selected);
        }

        saveLead() {
            this.$buefy.dialog.confirm({
                title: 'Lead-Update',
                message: `Telefonnummer dem ausgewählten Lead ${this.selected?.name} <strong>zuweisen</strong>?`,
                confirmText: 'Speichern',
                cancelText: 'Abbrechen',
                type: 'is-success',
                hasIcon: true,
                onConfirm: async () => {
                    await this.dispatchSaveLead();
                    this.$buefy.toast.open('Lead gespeichert.');
                },
            });
        }

        async getAsyncData(name: string) {
            // don't query when smaller than three characters.
            if (!name.length || name.length < 3) {
                this.data = [];
                return;
            }

            // start fetching
            this.isFetching = true;

            let response = await fetchLeads(name);

            if (response.status === 401 || response.status === 422) {
                await auth.dispatchRefreshToken();
                response = await fetchLeads(name);
            }

            if (response.parsedBody) {
                this.data = [];

                for (const lead of response.parsedBody) {
                    this.data.push(lead);
                }
            }

            // stop fetching
            this.isFetching = false;
        }
    }
