export enum InstaPhoneNumber {
    // Main numbers
    FREEPHONE_MAIN_AT = '+43800404064',
    FREEPHONE_MAIN_DE = '+498008080906',
    FREEPHONE_MAIN_ENG = '+448000488314',
    FREEPHONE_MAIN_CH = '+41800564259',

    GEOGRAPHIC_MAIN_AT = '+4319556717',
    GEOGRAPHIC_MAIN_DE = '+493020164910',
    GEOGRAPHIC_MAIN_CH = '+41435082373',
    GEOGRAPHIC_MAIN_UK = '+442038683746',
}
