import { HttpResponse, post } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { TicketCreateData } from '@/interfaces/tickets/TicketCreateData';
import { TicketCreateResponse } from '@/interfaces/tickets/TicketCreateResponse';

export async function addTicket(
    data: TicketCreateData,
): Promise<HttpResponse<TicketCreateResponse>> {
    return await post<TicketCreateResponse>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/tickets/`,
        data,
        TokenType.ACCESS,
    );
}
