import { render, staticRenderFns } from "./App.vue?vue&type=template&id=b0eaa90e&scoped=true"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"
import style0 from "./App.vue?vue&type=style&index=0&id=b0eaa90e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_t3gmm35n6tkn624j6aeupb7v5y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0eaa90e",
  null
  
)

export default component.exports