import { LeadCreateResponse } from '@/interfaces/leads/LeadCreateResponse';
import { LeadSearchData } from '@/interfaces/leads/LeadSearchData';
import { FixtureLevel } from '@/enums/FixtureLevel';
import { format, parseISO } from 'date-fns';
import { Country } from '@/enums/Country';
import { nextTick } from 'vue';

/**
 * Capitalize the first letter from given string/word.
 *
 * @param word
 * @return capitalized string
 */
export function capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

/**
 * Type guard for LeadCreateResponse and LeadSearchData
 *
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html
 * @param obj
 */
export function isLeadCreateResponse(
    obj: LeadCreateResponse | LeadSearchData,
): obj is LeadCreateResponse {
    return (obj as LeadCreateResponse).createData !== undefined;
}

/**
 * Build the url to 'jump' into odoo's contract record.
 *
 * @param carId erp contract id
 */
export function buildContractERPUrl(carId: number): string {
    const queryParams = `web#id=${carId}&action=392&model=instadrive_easing.contract&view_type=form&cids=1&menu_id=148`;

    return `//${process.env.VUE_APP_ODOO_HOST}/${queryParams}`;
}

/**
 * Build the url to 'jump' into the odoo's fleet (car) record.
 *
 * @param contractId erp car id
 */
export function buildCarERPUrl(contractId: number): string {
    const queryParams = `web#id=${contractId}&model=res.partner&view_type=form&cids=&menu_id=129`;

    return `//${process.env.VUE_APP_ODOO_HOST}/${queryParams}`;
}

/**
 * Get fixture name by fixture level.
 *
 * @param f FixtureLevel
 */
export function getFixtureLevelName(f: FixtureLevel): string {
    switch (f as FixtureLevel) {
        case FixtureLevel.LEVEL1:
            return 'Silber';
        case FixtureLevel.LEVEL2:
            return 'Gold';
        case FixtureLevel.LEVEL3:
            return 'Platin';
    }

    throw new Error('Unknown fixture level.');
}

/**
 * Build ERP's lead link to jump into.
 *
 * @param lead_id
 */
export function buildLeadLink(lead_id: number) {
    return `//${process.env.VUE_APP_ODOO_HOST}/web#id=${lead_id}&view_type=form&model=crm.lead`;
}

/**
 * Build ERP's partner link to jump into.
 *
 * @param partner_id
 */
export function buildPartnerLink(partner_id: number) {
    return `//${process.env.VUE_APP_ODOO_HOST}/web#id=${partner_id}&view_type=form&model=res.partner&menu_id=69&action=77`;
}

/**
 * Return a formatted date.
 *
 * @param d date string
 * @param customFormat date format, defaults to 'dd.MM.yyyy'
 */
export function formatDate(d: string | Date, customFormat = 'dd.MM.yyyy'): string {
    if (d instanceof Date) {
        return format(d, customFormat);
    }

    return format(parseISO(d), customFormat);
}

/**
 * Utility function to be used (always!) for displaying a price.
 *
 * @param amount
 * @param round whether to round the price or not
 */
export function displayPrice(amount: number, round = false) {
    const amountString = round
        ? Math.round(amount).toLocaleString('de')
        : amount.toLocaleString('de');

    return `€ ${amountString}`;
}

/**
 * Get the vehicle registration certificate name/word.
 *
 * @param c country
 */
export function vehicleRegistrationCert(c: Country): string {
    if (c === Country.AT) {
        return 'Zulassungsschein';
    }

    return 'Fahrzeugschein';
}

/**
 * Set the focus to the given HtmlInputElement.
 *
 * @param elem HTML input element
 */
export function focusElemRef(elem: HTMLInputElement | undefined) {
    if (elem) {
        nextTick(() => {
            elem.focus();
        });
    }
}
