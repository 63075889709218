
    import { Component, Vue } from 'vue-property-decorator';
    import auth from '@/store/auth/auth';
    import { getUserGroups, getUsername } from '@/utils/jwt';
    import { SvgIconPath } from '@/utils/SvgIconPath';
    import SvgIcon from '@/components/SvgIcon.vue';
    import { EventBus } from '@/main';
    import { GlobalEvent } from '@/enums/GlobalEvent';

    @Component({
        components: {
            SvgIcon,
        },
    })
    export default class Header extends Vue {
        private showUserProfile = false;
        private svgIcon = SvgIconPath;

        get auth() {
            return auth;
        }

        get username() {
            return getUsername(auth.loginData?.access_token);
        }

        get userGroups() {
            return JSON.stringify(getUserGroups(auth.loginData?.access_token), null, 2);
        }

        logout() {
            EventBus.$emit(GlobalEvent.LOGOUT);
        }
    }
