
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { format, utcToZonedTime } from 'date-fns-tz';
    import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
    // @ts-ignore
    import { findFlagUrlByIso2Code } from 'country-flags-svg';
    import DetailTable from '@/components/voice/DetailTable.vue';
    import { ActualCall } from '@/interfaces/voice/ActualCall';
    import { ActualCallType } from '@/enums/voice/ActualCallType';
    import { SIPAccount } from '@/interfaces/SIPAccount';
    import voice from '@/store/voice/voice';
    import LeadSearch from '@/components/LeadSearch.vue';
    import { LeadSearchData } from '@/interfaces/leads/LeadSearchData';
    import { CallerInfo } from '@/interfaces/voice/CallerInfo';
    import { CallerType } from '@/enums/voice/CallerType';
    import { buildCallerType } from '@/utils/voice';
    import auth from '@/store/auth/auth';
    import { addCallerinfo } from '@/api/Voice';
    import { CallDetail } from '@/interfaces/voice/CallDetail';
    import SvgIcon from '@/components/SvgIcon.vue';
    import { SvgIconPath } from '@/utils/SvgIconPath';
    import LeadCreation from '@/components/LeadCreation.vue';
    import { LeadCreateResponse } from '@/interfaces/leads/LeadCreateResponse';
    import { buildLeadLink, buildPartnerLink, isLeadCreateResponse } from '@/utils/misc';
    import NoticeAdd from '@/components/NoticeAdd.vue';
    import { CallerInfoRequest } from '@/interfaces/voice/CallerInfoRequest';
    import TicketAdd from '@/components/TicketAdd.vue';

    const formatDuration = require('format-duration');

    @Component({
        components: {
            TicketAdd,
            NoticeAdd,
            LeadCreation,
            SvgIcon,
            LeadSearch,
            DetailTable,
        },
    })
    export default class CallDetailRecord extends Vue {
        @Prop() item!: ActualCall;
        @Prop() currentCalls!: ActualCall[];
        private callerNumber: PhoneNumber | null = null;

        private assocSipAcc: SIPAccount | null = null;
        private duration: number | null = 0;

        private svgFlagUrl = '';
        private tooltip = '';
        private callLog: string[] = [];
        private showDetails = false;
        private showLeadCreation = false;
        private showNoticeAdding = false;
        private showTicketAdding = false;

        private cgt: unknown;
        private ct: unknown;

        private svgicon = SvgIconPath;

        /**
         * Get the erp link according to caller type.
         * @param erp_id
         */
        getERPLink(erp_id: number): string {
            if (this.item.callerInfo?.type === CallerType.LEAD) {
                return buildLeadLink(erp_id);
            } else if (this.item.callerInfo?.type === CallerType.CUSTOMER) {
                return buildPartnerLink(erp_id);
            }

            return '';
        }

        get callerType() {
            if (this.item.callerInfo) {
                return buildCallerType(this.item.callerInfo.type);
            }

            return '';
        }

        /**
         * Event handler for saveLead coming from LeadSearch or LeadCreate component.
         *  - Adding CallerInfo to Call in DB
         *  - Refresh view to show the new callerinfo.
         *
         * @param data
         */
        async onSaveLead(data: LeadSearchData | LeadCreateResponse) {
            // create callerinfo object
            let callerinfo: CallerInfo;

            if (isLeadCreateResponse(data)) {
                if (!data.createData) {
                    throw new Error('createData or phone number missing, cannot save lead.');
                }

                callerinfo = {
                    id: data.id,
                    name: data.createData.name,
                    email: data.createData.email_from || '',
                    type: CallerType.LEAD,
                };
            } else {
                callerinfo = {
                    id: data.id,
                    name: data.name,
                    email: data.email_from,
                    type: CallerType.LEAD,
                };
            }

            const calldetail: CallDetail = this.item.calls[0];

            if (!calldetail || !this.item.externalNumber) {
                throw new Error('Cannot get CallDetail or external number from ActualCall.');
            }

            const cir: CallerInfoRequest = {
                phone_number: this.item.externalNumber,
                data: {
                    callerInfo: callerinfo,
                },
            };

            // add callerinfo to cdr
            let response = await addCallerinfo(cir);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();
                response = await addCallerinfo(cir);
            }

            if (!response || !(await response.parsedBody) || response.status !== 200) {
                throw new Error('Error while saving lead.');
            }

            // refresh view data
            await voice.dispatchRetrieveCalls();
        }

        get groupSimilar() {
            return voice.state.groupSimilar;
        }

        formatDuration(duration: number): string {
            if (duration) {
                return formatDuration(duration * 1000);
            }

            return '';
        }

        formatDate(timestamp: string): string {
            if (timestamp) {
                const d = utcToZonedTime(new Date(timestamp), 'Europe/Vienna');

                return format(d, 'd.M.yyyy HH:mm:ss', { timeZone: 'Europe/Vienna' });
            }

            return '';
        }

        buildTooltip() {
            let ret = '';

            if (this.item.type === ActualCallType.INBOUND) {
                ret = 'Eingehender Anruf';

                if (this.item.missed) {
                    ret += ' (verpasst)';
                }
            } else if (this.item.type === ActualCallType.OUTBOUND) {
                ret = 'Ausgehender Anruf';

                if (this.item.missed) {
                    ret += ' (verpasst)';
                }
            }

            return ret;
        }

        /**
         * Show a phone log (who did the recent calls to this person).
         */
        buildCallLog() {
            // no sip username assigned or no external number, call log makes no sense
            if (!this.item.sipUsername || !this.item.externalNumber) {
                return [];
            }

            const log: string[] = [];

            for (const ac of this.currentCalls) {
                // limit the log to 5
                if (log.length === 5) {
                    break;
                }

                if (ac.externalNumber === this.item.externalNumber && ac.sipUsername) {
                    // get the sip account object
                    const sipacc = voice.state.availableSipAccounts.find(
                        (acc) => acc.username === this.item.sipUsername,
                    );

                    if (sipacc) {
                        // add the abbreviation to the collection
                        log.push(sipacc.abbr);
                    }
                }
            }

            return log;
        }

        mounted() {
            if (this.item.externalNumber) {
                if (this.item.externalNumber === 'anonymXXX') {
                    this.callerNumber = null;
                } else {
                    this.callerNumber = parsePhoneNumber(this.item.externalNumber);
                }

                if (this.callerNumber) {
                    this.svgFlagUrl = findFlagUrlByIso2Code(this.callerNumber.country);
                }

                if (this.item.sipUsername) {
                    const sipacc = voice.state.availableSipAccounts.find(
                        (acc) => acc.username === this.item.sipUsername,
                    );

                    if (sipacc) {
                        this.assocSipAcc = sipacc;
                    }
                }

                this.duration = this.item.calls
                    .map((item) => item.duration)
                    // sum it (or use 0)
                    .reduce((prev, curr) => (prev || 0) + (curr || 0), 0);
            }

            this.tooltip = this.buildTooltip();
            this.callLog = this.buildCallLog();
        }

        beforeCreate() {
            this.cgt = ActualCallType;
            this.ct = CallerType;
        }
    }
