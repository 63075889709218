import mitt, { Emitter } from 'mitt';

type Events = {
    showLoader: undefined;
    hideLoader: undefined;
};

const emitter: Emitter<Events> = mitt<Events>();

export default emitter;
