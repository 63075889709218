
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { LeadCreateData } from '@/interfaces/leads/LeadCreateData';
    import { addLead } from '@/api/Leads';
    import auth from '@/store/auth/auth';
    import { BModalComponent } from 'buefy/types/components';

    @Component
    export default class LeadCreation extends Vue {
        @Prop() externalNumber!: string;

        private formData: LeadCreateData = {
            name: '',
            phone: this.externalNumber,
            email_from: '',
        };

        private isValidName = false;

        @Watch('formData.name')
        onNameChange() {
            this.isValidName = Boolean(this.formData.name.length);
        }

        async dispatchCreateLead() {
            let response = await addLead(this.formData);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();
                response = await addLead(this.formData);
            }

            if (!response || response.status !== 201) {
                throw new Error('Error while creating lead.');
            }

            // add LeadCreateData to response
            const body = await response.parsedBody;

            if (!body) {
                throw new Error('Error while creating lead.');
            }

            body.createData = this.formData;

            return body;
        }

        async createLead() {
            // dispatch lead creation
            const lead_resp = await this.dispatchCreateLead();

            // tell parent component to react to lead saving
            this.$emit('saveLead', lead_resp);

            // close modal window
            this.close();

            this.$buefy.toast.open('Neuer Lead erstellt.');
        }

        close() {
            (this.$parent as BModalComponent).close();
        }
    }
