/**
 * Global custom application events.
 */
export enum GlobalEvent {
    // used for failed request due to authentication errors.
    AUTH_FAILED = 'auth-failed',

    // used for actual failed login attempts (on login view).
    LOGIN_FAILED = 'login-failed',

    // used for showing progress bar
    START_LOADING = 'start-loading',
    STOP_LOADING = 'stop-loading',

    OPEN_SIDEBAR = 'open-sidebar',
    CLOSE_SIDEBAR = 'close-sidebar',

    LOGOUT = 'logout',
}
