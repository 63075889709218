import { get, HttpResponse, post } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { Contract } from '@/interfaces/contracts/Contract';
import { ContractGeneratorResult } from '@/interfaces/contracts/ContractGeneratorResult';
import { ErpCustomer } from '@/interfaces/Customer';
import auth from '@/store/auth/auth';
import { getUsername } from '@/utils/jwt';
import { ErpCar } from '@/interfaces/cars/ErpCar';
import { ContractGeneratorRequest } from '@/interfaces/contracts/ContractGeneratorRequest';
import { ContractGeneratorRequestType } from '@/enums/contracts/ContractGeneratorRequestType';

export async function fetchContracts(): Promise<HttpResponse<Contract[]>> {
    return await get<Contract[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/contracts/`,
        TokenType.ACCESS,
    );
}

/**
 * Generate the contract by calling the appropriate crm's api endpoint.
 *
 * @param type
 * @param obj order or offer
 * @param existingCustomer
 * @param existingCar
 */
export async function generateContract(
    type: ContractGeneratorRequestType,
    obj: ContractGeneratorRequest,
    existingCustomer: ErpCustomer | null = null,
    existingCar: ErpCar | null = null,
): Promise<HttpResponse<ContractGeneratorResult>> {
    const data = {
        type: type,
        obj: obj,
        partner: existingCustomer,
        car: existingCar,
        user: getUsername(auth.loginData?.access_token),
    };

    return await post<ContractGeneratorResult>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/contractgenerator/`,
        data,
        TokenType.ACCESS,
    );
}
