import { cloneDeep } from 'lodash';

// @ts-ignore
export default function resetStore({ store }) {
    const initialState = cloneDeep(store.$state);

    store.$reset = () => {
        store.$patch(cloneDeep(initialState));
    };
}
