
    import { Component, Vue } from 'vue-property-decorator';
    import Header from '@/components/contracts/Header.vue';
    import ContractList from '@/components/contracts/ContractList.vue';

    @Component({
        components: {
            ContractList,
            Header,
        },
    })
    export default class Contracts extends Vue {}
