
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { ActualCall } from '@/interfaces/voice/ActualCall';

    @Component
    export default class DetailTable extends Vue {
        @Prop() data!: ActualCall;
        private output: string | null = null;

        mounted() {
            // output this as pretty json string ;-)
            // nobody knows what's going on but print it out
            this.output = JSON.stringify(this.data, null, 2);
        }
    }
