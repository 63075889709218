import { BareActionContext, getStoreBuilder } from 'vuex-typex';
import { RootState } from '@/store';
import { CallDetail } from '@/interfaces/voice/CallDetail';
import { fetchCalls, fetchSipAccounts } from '@/api/Voice';
import { CallType } from '@/enums/voice/CallType';
import { SIPAccount } from '@/interfaces/SIPAccount';
import { CDRRequest } from '@/interfaces/voice/CDRRequest';
import { InstaPhoneNumber } from '@/enums/voice/InstaPhoneNumber';
import { Voice } from '@/enums/user/groups/voice';
import auth from '@/store/auth/auth';
import { CallFilter } from '@/enums/voice/CallFilter';

export interface VoiceState {
    currentPhoneNumber: InstaPhoneNumber | Voice | null;
    currentSipAccount: SIPAccount | null;
    chosenDateFrom: Date | null;
    chosenDateTo: Date | null;
    groupSimilar: boolean;
    callFilter: CallFilter;
    currentCalls: CallDetail[];
    availableSipAccounts: SIPAccount[];
}

const initialVoiceState: VoiceState = {
    currentPhoneNumber: null,
    currentSipAccount: null,
    chosenDateFrom: null,
    chosenDateTo: null,
    groupSimilar: true,
    callFilter: CallFilter.NO_FILTER,
    currentCalls: [],
    availableSipAccounts: [],
};

const b = getStoreBuilder<RootState>().module('voice', initialVoiceState);

// getter
const currentPhoneNumberGetter = b.read((state) => state.currentPhoneNumber, 'currentPhoneNumber');
const currentSipAccountGetter = b.read((state) => state.currentSipAccount, 'currentSipAccount');
const chosenDateFormGetter = b.read((state) => state.chosenDateFrom, 'chosenDateFrom');
const chosenDateToGetter = b.read((state) => state.chosenDateTo, 'chosenDateTo');
const groupSimilarGetter = b.read((state) => state.groupSimilar, 'groupSimilar');
const callFilterGetter = b.read((state) => state.callFilter, 'callFilter');
const currentCallsGetter = b.read((state) => state.currentCalls, 'currentCalls');
const availableSipAccountsGetter = b.read(
    (state) => state.availableSipAccounts,
    'availableSipAccounts',
);

// mutations
function setCurrentPhoneNumber(
    state: VoiceState,
    payload: { currentPhoneNumber: InstaPhoneNumber | Voice | null },
) {
    state.currentPhoneNumber = payload.currentPhoneNumber;
}

function setCurrentSipAccount(
    state: VoiceState,
    payload: { currentSipAccount: SIPAccount | null },
) {
    state.currentSipAccount = payload.currentSipAccount;
}

function setChosenDateFrom(state: VoiceState, payload: { chosenDateFrom: Date | null }) {
    state.chosenDateFrom = payload.chosenDateFrom;
}

function setChosenDateTo(state: VoiceState, payload: { chosenDateTo: Date | null }) {
    state.chosenDateTo = payload.chosenDateTo;
}

function setGroupSimilar(state: VoiceState, payload: { groupSimilar: boolean }) {
    state.groupSimilar = payload.groupSimilar;
}

function setCallFilter(state: VoiceState, payload: { callFilter: CallFilter }) {
    state.callFilter = payload.callFilter;
}

function setCurrentCalls(state: VoiceState, payload: { currentCalls: CallDetail[] }) {
    state.currentCalls = payload.currentCalls;
}

function setAvailableSipAccounts(
    state: VoiceState,
    payload: { availableSipAccounts: SIPAccount[] },
) {
    state.availableSipAccounts = payload.availableSipAccounts;
}

// actions
async function retrieveSipAccounts() {
    let response = await fetchSipAccounts();

    if (response.status === 401 || response.status === 422) {
        await auth.dispatchRefreshToken();
        response = await fetchSipAccounts();
    }

    if (response.parsedBody) {
        voice.commitAvailableSipAccounts({ availableSipAccounts: response.parsedBody });
    } else {
        throw new Error('cannot get available sip accounts');
    }
}

/**
 * Retrieve the current Calls.
 *
 * @param context
 */
async function retrieveCurrentCalls(context: BareActionContext<VoiceState, RootState>) {
    const reqData: CDRRequest = {
        call_types: [CallType.INBOUND, CallType.OUTBOUND, CallType.SIPFORWARD_USER],
        phone_number: context.state.currentPhoneNumber,
        date_from: context.state.chosenDateFrom,
        date_to: context.state.chosenDateTo,
    };

    if (context.state.currentSipAccount) {
        reqData.username = context.state.currentSipAccount.username;
    }

    let response = await fetchCalls(reqData);

    if (response.status === 401 || response.status === 422) {
        await auth.dispatchRefreshToken();
        response = await fetchCalls(reqData);
    }

    const calls = await response.parsedBody;

    if (calls && Array.isArray(calls)) {
        voice.commitCurrentCalls({ currentCalls: calls });
    } else {
        throw new Error('cannot get current calls!');
    }
}

// state
const stateGetter = b.state();

const voice = {
    // state
    get state() {
        return stateGetter();
    },

    // getters (wrapped as real getters)
    get currentPhoneNumber() {
        return currentPhoneNumberGetter();
    },
    get currentSipAccount() {
        return currentSipAccountGetter();
    },
    get chosenDateFrom() {
        return chosenDateFormGetter();
    },
    get chosenDateTo() {
        return chosenDateToGetter();
    },
    get groupSimilar() {
        return groupSimilarGetter();
    },
    get callFilter() {
        return callFilterGetter();
    },
    get currentCalls() {
        return currentCallsGetter();
    },
    get availableSipAccounts() {
        return availableSipAccountsGetter();
    },

    // mutations
    commitCurrentPhoneNumber: b.commit(setCurrentPhoneNumber),
    commitCurrentSipAccount: b.commit(setCurrentSipAccount),
    commitChosenDateFrom: b.commit(setChosenDateFrom),
    commitChosenDateTo: b.commit(setChosenDateTo),
    commitGroupSimilar: b.commit(setGroupSimilar),
    commitCallFilter: b.commit(setCallFilter),
    commitCurrentCalls: b.commit(setCurrentCalls),
    commitAvailableSipAccounts: b.commit(setAvailableSipAccounts),

    // actions
    dispatchRetrieveCalls: b.dispatch(retrieveCurrentCalls),
    dispatchRetrieveSipAccounts: b.dispatch(retrieveSipAccounts),
};

export default voice;
