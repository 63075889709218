import { BareActionContext, getStoreBuilder } from 'vuex-typex';
import { RootState } from '@/store';
import { LoginResponse } from '@/interfaces/LoginResponse';
import { isValidJwt } from '@/utils/jwt';
import { LoginRequest } from '@/interfaces/LoginRequest';
import {
    fetchAPIKeys,
    fetchLoginData,
    processLogoutAccess,
    processLogoutRefresh,
    refreshToken,
} from '@/api/Auth';
import { EventBus } from '@/main';
import { GlobalEvent } from '@/enums/GlobalEvent';
import { APIKeys } from '@/interfaces/APIKeys';

export interface AuthState {
    loginData: LoginResponse | null;
    loginReq: LoginRequest | null;
    apiKeys: APIKeys | null;
}

const initialAuthState: AuthState = {
    loginData: null,
    loginReq: null,
    apiKeys: null,
};

const b = getStoreBuilder<RootState>().module('auth', initialAuthState);

// getter
const loginDataGetter = b.read((state) => state.loginData, 'loginData');
const loginReqGetter = b.read((state) => state.loginReq, 'loginReq');
const apiKeysGetter = b.read((state) => state.apiKeys, 'apiKeys');
const isAuthenticatedGetter = b.read((state) => {
    // check for valid refresh token
    // if the token is invalid, go to Login View.
    return isValidJwt(state.loginData?.refresh_token);
}, 'isAuthenticated');

// mutations
function setLoginData(state: AuthState, payload: { loginData: LoginResponse | null }) {
    state.loginData = payload.loginData;
}

function setLoginReq(state: AuthState, payload: { loginReq: LoginRequest | null }) {
    state.loginReq = payload.loginReq;
}

function setApiKeys(state: AuthState, payload: { apiKeys: APIKeys | null }) {
    state.apiKeys = payload.apiKeys;
}

// actions
async function retrieveLoginData(context: BareActionContext<AuthState, RootState>) {
    if (!context.state.loginReq) {
        console.error('loginReq evaluates to false, unknown error');
        EventBus.$emit(GlobalEvent.LOGIN_FAILED);
        return;
    }

    const response = await fetchLoginData(context.state.loginReq);

    if (
        response.parsedBody &&
        response.parsedBody.access_token &&
        response.parsedBody.refresh_token
    ) {
        auth.commitLoginData({ loginData: response.parsedBody });
    } else {
        // emitting LOGIN_FAILED event, Login component should catch it
        EventBus.$emit(GlobalEvent.LOGIN_FAILED);
        return;
    }
}

async function retrieveAPIKeys() {
    const response = await fetchAPIKeys();

    if (response.parsedBody) {
        auth.commitApiKeys({ apiKeys: response.parsedBody });
    } else {
        console.error('Cannot retrieve api keys');
        return;
    }
}
/**
 * Unauthorized or similar error from JWT, try to use retrieving a new token,
 * This means the access_token is obviously expired, so use the refresh_token to get a new one.
 */
async function retrieveNewToken(context: BareActionContext<AuthState, RootState>) {
    console.info('Refreshing token...');
    let response;

    try {
        response = await refreshToken();
    } catch (ex) {
        console.error('Exception while refreshing token.');
        // getting new access_token obviously failed, so emit AUTH_FAILED event
        EventBus.$emit(GlobalEvent.AUTH_FAILED);
    }

    if (!response || response.status !== 200) {
        // getting new access_token obviously failed, so emit AUTH_FAILED event and remove local jwt
        auth.commitLoginData({ loginData: null });
        EventBus.$emit(GlobalEvent.AUTH_FAILED);
    }

    if (response && response.parsedBody && context.state.loginData) {
        const loginData: LoginResponse = {
            access_token: response.parsedBody.access_token, // set new access token from response
            refresh_token: context.state.loginData.refresh_token,
            message: context.state.loginData.message,
        };

        auth.commitLoginData({ loginData });
    }
}

/**
 * Processing Logout (both, revoke access and refresh token).
 */
async function processLogout() {
    const access_response = await processLogoutAccess();
    const refresh_response = await processLogoutRefresh();

    if (access_response.ok && refresh_response.ok) {
        console.info('Logging out successful');
    } else if (
        access_response.status === 401 ||
        access_response.status === 422 ||
        refresh_response.status == 401 ||
        refresh_response.status === 422
    ) {
        // logging out failed due to auth error
        EventBus.$emit(GlobalEvent.AUTH_FAILED);
    } else {
        // any
        console.error('Unknown error while logging out.');
    }

    // remove the loginData/JWT anyway
    auth.commitLoginData({ loginData: null });
}

// state
const stateGetter = b.state();

const auth = {
    // state
    get state() {
        return stateGetter();
    },

    // getters (wrapped as real getters)
    get loginData() {
        return loginDataGetter();
    },
    get loginReq() {
        return loginReqGetter();
    },
    get apiKeys() {
        return apiKeysGetter();
    },
    get isAuthenticated() {
        return isAuthenticatedGetter();
    },

    // mutations
    commitLoginData: b.commit(setLoginData),
    commitLoginReq: b.commit(setLoginReq),
    commitApiKeys: b.commit(setApiKeys),

    // actions
    dispatchLogin: b.dispatch(retrieveLoginData),
    dispatchRefreshToken: b.dispatch(retrieveNewToken),
    dispatchAPIKeys: b.dispatch(retrieveAPIKeys),
    dispatchLogout: b.dispatch(processLogout),
};

export default auth;
