import { get, HttpResponse, post } from '@/api/Api';
import { LoginResponse } from '@/interfaces/LoginResponse';
import { LoginRequest } from '@/interfaces/LoginRequest';
import { RefreshTokenResponse } from '@/interfaces/RefreshTokenResponse';
import { TokenType } from '@/enums/TokenType';
import { APIKeys } from '@/interfaces/APIKeys';

export async function fetchLoginData(reqData: LoginRequest): Promise<HttpResponse<LoginResponse>> {
    return await post<LoginResponse>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/auth/login/`,
        reqData,
    );
}

export async function fetchAPIKeys(): Promise<HttpResponse<APIKeys>> {
    return await get<APIKeys>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/auth/api-keys/`,
        TokenType.ACCESS,
    );
}

export async function refreshToken(): Promise<HttpResponse<RefreshTokenResponse>> {
    return await post<RefreshTokenResponse>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/auth/token/refresh/`,
        {},
        TokenType.REFRESH,
    );
}

export async function processLogoutAccess(): Promise<HttpResponse<null>> {
    return await post<null>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/auth/logout/access/`,
        {},
        TokenType.ACCESS,
    );
}

export async function processLogoutRefresh(): Promise<HttpResponse<null>> {
    return await post<null>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/auth/logout/refresh/`,
        {},
        TokenType.REFRESH,
    );
}
