import { TokenType } from '@/enums/TokenType';
import auth from '@/store/auth/auth';
import { getUsername } from '@/utils/jwt';

export interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

async function blob(request: RequestInfo): Promise<HttpResponse<Blob>> {
    return await fetch(request);
}

async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetch(request);

    try {
        // may error if there is no 'body', ignore 204 = NO CONTENT, 409 = CONFLICT
        if (response.status !== 204 && response.status !== 409) {
            response.parsedBody = await response.json();
        }
    } catch (ex) {
        throw new Error('no body in response');
    }

    const allowedHttpStatus = [404, 401, 409, 422, 303, 204, 201];

    if (!response.ok && !allowedHttpStatus.includes(response.status)) {
        throw new Error(response.statusText);
    }

    return response;
}

export async function getBlob(
    path: string,
    tokenType: TokenType = TokenType.NONE,
): Promise<string> {
    const args: RequestInit = { method: 'GET' };

    if (tokenType !== TokenType.NONE) {
        const requestHeaders: HeadersInit = new Headers();
        args.headers = requestHeaders;

        if (tokenType === TokenType.THG_API_KEY) {
            if (!auth.state.apiKeys || !auth.state.apiKeys.thg_service_api_key) {
                throw new Error('cannot get thg api key');
            }

            requestHeaders.set('thg-api-key', auth.state.apiKeys.thg_service_api_key);
        }

        if (tokenType === TokenType.ACCESS) {
            args.credentials = 'include';
            requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.access_token}`);
        } else if (tokenType === TokenType.REFRESH) {
            args.credentials = 'include';
            requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.refresh_token}`);
        }
    }

    const resp = await blob(new Request(path, args));

    return URL.createObjectURL(await resp.blob());
}

export async function get<T>(
    path: string,
    tokenType: TokenType = TokenType.NONE,
): Promise<HttpResponse<T>> {
    const args: RequestInit = { method: 'GET' };

    if (tokenType !== TokenType.NONE) {
        const requestHeaders: HeadersInit = new Headers();
        args.headers = requestHeaders;

        if (tokenType === TokenType.THG_API_KEY) {
            if (!auth.state.apiKeys || !auth.state.apiKeys.thg_service_api_key) {
                throw new Error('cannot get thg api key');
            }

            requestHeaders.set('thg-api-key', auth.state.apiKeys.thg_service_api_key);
        }

        if (tokenType === TokenType.ACCESS) {
            args.credentials = 'include';
            requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.access_token}`);
        } else if (tokenType === TokenType.REFRESH) {
            args.credentials = 'include';
            requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.refresh_token}`);
        }
    }

    return await http<T>(new Request(path, args));
}

export async function patch<T>(
    path: string,
    body: unknown,
    tokenType: TokenType = TokenType.NONE,
): Promise<HttpResponse<T>> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    if (tokenType === TokenType.ACCESS) {
        requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.access_token}`);
    } else if (tokenType === TokenType.REFRESH) {
        requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.refresh_token}`);
    } else if (tokenType === TokenType.THG_API_KEY) {
        if (!auth.state.apiKeys || !auth.state.apiKeys.thg_service_api_key) {
            throw new Error('cannot get thg api key');
        }

        const currentUsername = getUsername(auth.state.loginData?.access_token);

        requestHeaders.set('thg-api-key', auth.state.apiKeys.thg_service_api_key);
        requestHeaders.set('crm-username', currentUsername);
    }

    const args: RequestInit = {
        method: 'PATCH',
        headers: requestHeaders,
        body: JSON.stringify(body),
    };

    if (tokenType === TokenType.ACCESS || tokenType === TokenType.REFRESH) {
        args.credentials = 'include';
    }

    return await http<T>(new Request(path, args));
}

export async function post<T>(
    path: string,
    body: unknown,
    tokenType: TokenType = TokenType.NONE,
): Promise<HttpResponse<T>> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    if (tokenType === TokenType.ACCESS) {
        requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.access_token}`);
    } else if (tokenType === TokenType.REFRESH) {
        requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.refresh_token}`);
    } else if (tokenType === TokenType.THG_API_KEY) {
        if (!auth.state.apiKeys || !auth.state.apiKeys.thg_service_api_key) {
            throw new Error('cannot get thg api key');
        }

        const currentUsername = getUsername(auth.state.loginData?.access_token);

        requestHeaders.set('thg-api-key', auth.state.apiKeys.thg_service_api_key);
        requestHeaders.set('crm-username', currentUsername);
    }

    const args: RequestInit = {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(body),
    };

    if (tokenType === TokenType.ACCESS || tokenType === TokenType.REFRESH) {
        args.credentials = 'include';
    }

    return await http<T>(new Request(path, args));
}

export async function del<T>(
    path: string,
    body: unknown,
    tokenType: TokenType = TokenType.NONE,
): Promise<HttpResponse<T>> {
    const requestHeaders: HeadersInit = new Headers({
        'Content-Type': 'application/json',
    });

    if (tokenType === TokenType.ACCESS) {
        requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.access_token}`);
    } else if (tokenType === TokenType.REFRESH) {
        requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.refresh_token}`);
    } else if (tokenType === TokenType.THG_API_KEY) {
        if (!auth.state.apiKeys || !auth.state.apiKeys.thg_service_api_key) {
            throw new Error('cannot get thg api key');
        }
        const currentUsername = getUsername(auth.state.loginData?.access_token);

        requestHeaders.set('thg-api-key', auth.state.apiKeys.thg_service_api_key);
        requestHeaders.set('crm-username', currentUsername);
    }

    const args: RequestInit = {
        method: 'DELETE',
        headers: requestHeaders,
        body: JSON.stringify(body),
        credentials: 'include',
    };

    if (tokenType === TokenType.ACCESS || tokenType === TokenType.REFRESH) {
        args.credentials = 'include';
    }

    return await http<T>(new Request(path, args));
}

export async function put<T>(
    path: string,
    body: unknown,
    tokenType: TokenType = TokenType.NONE,
): Promise<HttpResponse<T>> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    if (tokenType === TokenType.ACCESS) {
        requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.access_token}`);
    } else if (tokenType === TokenType.REFRESH) {
        requestHeaders.set('Authorization', `Bearer ${auth.state.loginData?.refresh_token}`);
    } else if (tokenType === TokenType.THG_API_KEY) {
        if (!auth.state.apiKeys || !auth.state.apiKeys.thg_service_api_key) {
            throw new Error('cannot get thg api key');
        }

        requestHeaders.set('thg-api-key', auth.state.apiKeys?.thg_service_api_key);
    }

    const args: RequestInit = {
        method: 'PUT',
        headers: requestHeaders,
        body: JSON.stringify(body),
    };

    if (tokenType === TokenType.ACCESS || tokenType === TokenType.REFRESH) {
        args.credentials = 'include';
    }

    return await http<T>(new Request(path, args));
}
