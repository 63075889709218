import { defineStore } from 'pinia';
import { Country } from '@/enums/Country';
import { ref } from 'vue';

export const useTHGGlobalStore = defineStore(
    'thgglobal',
    () => {
        const country = ref<Country>(Country.DE);

        return {
            currentCountry: country,
        };
    },
    {
        persist: true,
    },
);
