
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { NoticeCreateData } from '@/interfaces/notices/NoticeCreateData';
    import auth from '@/store/auth/auth';
    import { addNotice } from '@/api/Leads';
    import { getUserAbbr } from '@/utils/jwt';
    import { BModalComponent } from 'buefy/types/components';

    @Component
    export default class NoticeAdd extends Vue {
        @Prop() leadId!: number;

        private formData: NoticeCreateData = {
            description: '',
        };

        private isValidText = false;

        @Watch('formData.description')
        onNameChange() {
            this.isValidText = Boolean(this.formData.description.length);
        }

        async dispatchAddNotice() {
            // prepend user abbreviation to notice
            this.formData.description = `${getUserAbbr(auth.loginData?.access_token)}: `.concat(
                this.formData.description,
            );

            let response = await addNotice(this.leadId, this.formData);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();
                response = await addNotice(this.leadId, this.formData);
            }

            if (
                !response ||
                !(await response.parsedBody) ||
                !response.parsedBody ||
                response.status !== 200
            ) {
                throw new Error('Error while adding notice.');
            }

            return response.parsedBody;
        }

        async createNotice() {
            // dispatch lead creation
            await this.dispatchAddNotice();

            // close modal window
            this.close();

            this.$buefy.toast.open('Notiz hinzugefügt.');
        }

        close() {
            (this.$parent as BModalComponent).close();
        }
    }
